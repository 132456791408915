import {APP_ROUTES} from '@shared/routes/appRoutes';
export const DASHBOARD_PERMISSION = 1;
export const MANAGE_ROLES_PERMISSION = 3;
export const MANAGE_USERS_PERMISSION = 4;
export const MANAGE_PROJECTS_PERMISSION = 5;
export const MANAGE_TOOLBOX_PERMISSION = 6;
export const MANAGE_LMRA_CHECKLIST_PERMISSION = 7;
export const MANAGE_ACCIDENT_PROCESS_PERMISSION = 8;
export const MANAGE_INSTRUCTION_INDUCTION_PERMISSION = 10;
export const SEND_MESSAGE_PERMISSION = 11;
export const DATA_ANALYSIS_PERMISSION = 12;
export const MY_ACCOUNT_PERMISSION = 2;

export const PERMISSIONS_URLS =
    {
        [DASHBOARD_PERMISSION] : ['/', APP_ROUTES.dashboard, APP_ROUTES.DashboardSiteOverView],
        [MANAGE_ROLES_PERMISSION]: ['/', APP_ROUTES.ManageRoles],
        [MANAGE_USERS_PERMISSION]: ['/', APP_ROUTES.ManageUsers],
        [MANAGE_LMRA_CHECKLIST_PERMISSION]: ['/', APP_ROUTES.ManageLMRA],
        [MANAGE_PROJECTS_PERMISSION]: null,
        [MANAGE_TOOLBOX_PERMISSION]: ['/', APP_ROUTES.ManageToolbox],
        [MANAGE_ACCIDENT_PROCESS_PERMISSION]: null,
        [MANAGE_INSTRUCTION_INDUCTION_PERMISSION]: ['/',APP_ROUTES.ManageInstructions],
        [SEND_MESSAGE_PERMISSION]: null,
        [DATA_ANALYSIS_PERMISSION]: null,
        [MY_ACCOUNT_PERMISSION]: ['/', APP_ROUTES.ManageAccount],
    };
