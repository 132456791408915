import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Location } from '@angular/common';
import {getRouteAccordingToRole} from '../helpers/functions';
@Injectable({
  providedIn: 'root'
})
export class GuestGuard implements CanActivate {

  constructor(private router: Router,private location: Location) {}
//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
//     if (localStorage.getItem('token') ) {
//         this.goBack();
//       return true
//     } else {
//     //   this.router.navigate(['/login']);
//       return false;
//     }
//   }

canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!localStorage.getItem('token')) {
        return true;
    } else {
      const {role_id : roleid , roleArr } = JSON.parse(localStorage.getItem('currentUser'));
      const path = getRouteAccordingToRole(roleid, roleArr);
      if (!path) {
        return true;
      }
      this.router.navigate(path as string[]);
      return false;
    }
  }

}
