import { Injectable } from '@angular/core';
import { Permission } from '@models/permission.model';
import { Role } from '@models/role.model';
import { APP_USER } from '@shared/constants/roles.constants';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  storeUser(user): boolean {
    if (this.storeRolePermissions(user)) {
      localStorage.setItem('token', user.token);
      localStorage.setItem('currentUser', JSON.stringify(user));
      return true;
    }
    return false;
  }

  getUserRole(): Role{
    return JSON.parse(localStorage.getItem('userRole'));
  }

  getPermissions(): Permission[] {
    return JSON.parse(localStorage.getItem('permissions'));
  }

  private storeRolePermissions(data): boolean {
    const {  UserProjects: projects } = data;
    const roles: Array<Role> = (projects as Array<any>).map((project) => project.Role) ;
    roles.sort((project1, project2) => {
      return project1.id - project2.id;
    });
    const role: Role = roles.find((r: Role) => Number(r.id) !== APP_USER);
    if (role) {
      localStorage.setItem('userRole', JSON.stringify(role));
      const permissions = role.Permissionfilter;
      localStorage.setItem('permissions', JSON.stringify(permissions));
      return true;
    }

    return false;
  }

}
