import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReadMoreComponent} from './components/read-more/read-more.component';
import {LocalStorageService} from './storage/local-storage.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import {CustomFormsModule} from 'ng2-validation';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import {DialogModule} from 'primeng/dialog';
import {HttpClientModule} from '@angular/common/http';
import { ValidatonErrorsComponent } from './components/validaton-errors/validaton-errors.component';
import { OrderModule } from 'ngx-order-pipe';
import { FunctionsDropdownComponent } from './components/functions-dropdown/functions-dropdown.component';
import { ToolTipDirective } from './directives/tool-tip.directive';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { SafehtmlPipe } from '../services/safehtml.pipe';


@NgModule({
  declarations: [
    ReadMoreComponent,
    ValidatonErrorsComponent,
    FunctionsDropdownComponent,
    ToolTipDirective,
    SafehtmlPipe
  ],
  imports: [
    CommonModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DialogModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      maxOpened: 1
    }),
    PaginationModule.forRoot(),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger', // set defaults here
    }),
    CustomFormsModule,
    NgSelectModule,
    NgxUiLoaderModule,
    OrderModule,
  ],
  providers: [
    LocalStorageService,
    SafehtmlPipe,
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'DEU'},
  ],
  exports: [
    SafehtmlPipe,
    ReadMoreComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule,
    PaginationModule,
    ConfirmationPopoverModule,
    CustomFormsModule,
    NgSelectModule,
    NgxUiLoaderModule,
    HttpClientModule,
    ValidatonErrorsComponent,
    OrderModule,
    FunctionsDropdownComponent,
    ToolTipDirective
  ]
})
export class SharedModule { }
