import { Component, OnInit } from '@angular/core';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { PreAuthPagesService } from '../../pre-auth-pages.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    public APP_ROUTES = APP_ROUTES;
    public forgotForm: FormGroup;
    public isSubmitting = false;
    public isDisabled = false;
    isForgotSuccess = false;
    submitted = false;

   constructor(
               private router: Router,
               private service: PreAuthPagesService,
               private toastr: ToastrService,
               private loaderService: NgxUiLoaderService) { }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
        this.forgotForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)])
        }, { updateOn: 'blur' });
  }

  clearFocus(element: HTMLElement): void {
    element.blur();
  }

  doForgot(e: Event): void {
    if (e.target) {
        this.clearFocus(e.target as HTMLElement);
    }
    this.submitted = true;
    this.isForgotSuccess = false;
    if (this.forgotForm.invalid) {
            this.isSubmitting = true;
            return;
        }
    this.isSubmitting = false;
    this.loaderService.start();
    this.service.forgotPassword(this.forgotForm.value).subscribe((data: any) => {
            this.loaderService.stop();
            if (data.status === 200) {
                setTimeout(() => {
                    this.toastr.success(data.body.message, '');
                }, 1000);
                this.isForgotSuccess = true;
                this.router.navigate([APP_ROUTES.login]).then(() => {
                    // for some async task
                });
            }
        }, err => {
            this.loaderService.stop();
            this.submitted = false;
            this.toastr.error(err.error.message, '');
        });
    }

    gotoLogin(): void{
    this.router.navigate([APP_ROUTES.login]).then(() => { });
  }

}
