<div class="login-page">

	<div class="login-box">
		<div class="login-logo mb-4">
			<a  href="javascript:;"><img src="/assets/img/logo.png" alt="logo"/></a>
		</div>
		<div class="card">
			<div class="card-body login-card-body">
			<h4 _ngcontent-lqd-c127="" class=" text-center f-700"> Forgot password </h4>
			<hr>
			<p class="login-box-msg">You forgot your password? Here you can easily retrieve a new password.</p>
		
			<form novalidate [formGroup]="forgotForm">
				<div class="form-group">
				<label>Forgot Password<span class="required-asterisk">*</span></label>
				<div class="input-group">
				<input type="email" class="form-control" placeholder="email"
				formControlName="email" autocomplete="off">
				<div class="input-group-append">
					<div class="input-group-text">
					<span class="fas fa-envelope"></span>
					</div>
				</div>
				</div>
	
				<div class="col-md-12">
				<ng-container
					*ngIf="forgotForm.controls['email'].errors && (forgotForm.get('email').dirty || forgotForm.get('email').touched || isSubmitting)">
					<small class="text-danger error-msg"
						[hidden]="!forgotForm.controls['email'].errors.required">*Email is
						required.</small>
					<small class="text-danger error-msg"
						[hidden]="!forgotForm.controls['email'].errors.pattern">*Email is
						invalid.</small>
				</ng-container>
			</div>
	
				</div>		
				<div class="row">
				<div class="col-12">
					<button type="submit" class="btn btn-primary btn-lg mt-3 pt-2 pb-2 btn-height fs-6 mw-120 d-inline-block mr-2" (click)="doForgot($event)">Submit</button>
					<button [disabled]="submitted" class="btn btn-lg btn-outline-primary mt-3 pt-2 pb-2 btn-height fs-6 mw-120 d-inline-block" (click)="gotoLogin()">Cancel</button>
				</div>
				<!-- /.col -->
				</div>
			</form>
		
			<!-- <p class="mt-3 mb-1">
				<a class="btn btn-block btn-outline-primary" href="javascript:;" [routerLink]="['../' + APP_ROUTES.login]">Login</a>
			</p> -->
			</div>
			<!-- /.login-card-body -->
		</div>
		</div>
	</div>
	
	
	