<div *ngIf="control && control.touched && control.errors" class="text-danger">
    <div *ngIf="control.errors.required && language!=='German'">{{controlName}} is required.</div>
    <div *ngIf="control.errors.required && language ==='German'">{{controlName}} ist erforderlich.</div>
    <div *ngIf="control.errors.whitespace">{{controlName}} should not contain whitespace.</div>
    <div *ngIf="control.errors.maxlength">{{controlName}} should only contain {{control.errors.maxlength.requiredLength}} characters but found {{control.errors.maxlength.actualLength}} character(s).</div>
    <div *ngIf="control.errors.editorMaxChar">{{controlName}} should only contain 3000 characters but found {{control.errors.count}} character(s).</div>
    <div *ngIf="control.errors.digits">{{controlName}} should only contain digits.</div>
    <div *ngIf="control.errors.email">Invalid email format.</div>
    <div *ngIf="control.errors.rangeLength">{{controlName}} should have 11 digits.</div>
    <div *ngIf="control.errors.owlDateTimeMin">
        Selected data time {{ getFormattedDate(control.errors.owlDateTimeMin.actual) }}  is greater than minimum date {{ getFormattedDate(control.errors.owlDateTimeMin.min)}}.
    </div>
    <div *ngIf="control.errors.pattern">
        <ng-content select=".pattern-error">
        </ng-content>
    </div>
</div>
