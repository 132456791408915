// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // apiBaseUrl : 'http://112.196.1.221:9020/',
  // imageBaseUrl: 'http://112.196.1.221:9020/uploads/',
  apiBaseUrl : 'https://wwvapi.signitydemo.in/',
  imageBaseUrl: 'https://d1um48jugaalri.cloudfront.net/',
  incidentMediaUrl:'https://wwvapi.signitydemo.in/uploads/incident/',
  inductionMediaUrl:'https://wwvapi.signitydemo.in/uploads/induction/',
  production: false,
  itemsPerPage : 10
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
