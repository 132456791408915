import { Injectable } from '@angular/core';
import { ApiRoutes } from 'src/app/shared/routes/apiRoutes';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'src/app/shared/storage/local-storage.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import * as roles from '../../shared/constants/roles.constants';
import { TokenService } from 'src/app/services/token.service';

@Injectable({
  providedIn: 'root'
})
export class PreAuthPagesService {

  private authToken: string;
  private authState: BehaviorSubject<boolean>;
  
  constructor(private http: HttpClient,
			  private ls: LocalStorageService,
			  private router: Router,
			  private tokenService: TokenService) { }

	/**
	* Login action
	* @param payload is credentials of the user
	*/
	public login(payload: any): Observable<any> {
		const url: string = ApiRoutes.login;
		return this.http.post(url, payload, { observe: 'response' }).pipe(map((data: any) => {
			let isValidUser = true;
			if (data.status === 200) {
				isValidUser = this.tokenService.storeUser(data.body.user);
				this.token = data.token;
			}
			return {...data, isValidUser };
		}));
  }
  
  /**
	* Login action
	* @param token is JWT token
	*/
	public set token(token: string) {
		this.authToken = token;
		// this.changeAuthState = !!token;
	}

	//* get auth token */
	public get token(): string {
		return this.authToken ? this.authToken : '';
	}

	/**
	* Change auth state of a user
	* @param newState of the user
	*/
	public set changeAuthState(newState: boolean) {
		this.authState.next(newState);
	}

	//** Method to check if user logged in */
	public isAuthenticated(): boolean {
		return !!this.token;
	}

	//** Logout action */
	public logout(): void {
		this.ls.clear();
		this.token = null;
		// this.changeAuthState = !!this.token;
		this.router.navigate([APP_ROUTES.login]);
  }

  /**
	* Service for forget password
	* @param payload is credentials of the user
	*/
  public forgotPassword(payload: any): Observable<any> {
	const url: string = ApiRoutes.forgotPassword;
	return this.http.post(url, payload, { observe: 'response' });
}


checkResetToken(resetToken): Observable<any>{
	const url: string = ApiRoutes.checkResetToken;
	return this.http.post(url, {token : resetToken}, { observe: 'response' });
}
public changePassword(payload: any): Observable<any> {
	const url: string = ApiRoutes.changePassword;
	return this.http.post(url, payload, { observe: 'response' });
}
}
