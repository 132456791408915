<div class="login-page">
    <div class="login-box">
        <div class="login-logo mb-4">
            <a href="javascript:;"><img src="/assets/img/logo.png" alt="logo" /></a>
        </div>
        <!-- /.login-logo -->
        <div class="card">
           
            <div class="card-body login-card-body">
                <h4 class="text-center f-700">Login</h4>
                <hr>
                <form novalidate [formGroup]="LoginForm">
                    <div class="form-group">
                        <label>Employee Code<span class="required-asterisk">*</span></label>
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="employee_code" autocomplete="off" placeholder="Your Employee Code.">

                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-envelope"></span>
                                </div>
                            </div>

                        </div>
                        <ng-container *ngIf="LoginForm.controls['employee_code'].errors &&
								(LoginForm.get('employee_code').dirty || LoginForm.get('employee_code').touched ||
								isSubmitting)">
                            <small class="text-danger error-msg" [hidden]="!LoginForm.controls['employee_code'].errors.required">*Employee Code is
									required.
                            </small>
                        </ng-container>

                    </div>
                    <div class="form-group">
                        <label>Password<span class="required-asterisk">*</span></label>
                        <div class="input-group">

                            <input type="{{passwordVisibility ? 'text' : 'password'}}" class="form-control" formControlName="password" autocomplete="off" placeholder="Password">

                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-lock"></span>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="LoginForm.controls['password'].errors &&
					(LoginForm.get('password').dirty || LoginForm.get('password').touched ||
					isSubmitting)">
                    <small class="text-danger error-msg" [hidden]="!LoginForm.controls['password'].errors.required">*Password is required.</small>
                        </ng-container>

                    </div>
                    <div class="row">
                        <!-- <div class="col-8">
			  <div class="icheck-primary">
				<input type="checkbox" id="remember">
				<label for="remember">
				  Remember Me
				<span class="required-asterisk">*</span></label>
			  </div>
			</div> -->
                        <!-- /.col -->

                        <div class="col-12 text-right">
                            <p class="mb-1">
                                <a href="javascript:;" [routerLink]="['../' + APP_ROUTES.forgotPassword]">I
						forgot my password</a>
                            </p>
                        </div>


                        <div class="col-12 text-center mt-3">
                            <button type="submit" class="btn btn-primary btn-block btn-lg" (click)="doLogin($event)">Sign In</button>
                        </div>
                        <!-- /.col -->
                    </div>
                </form>

            </div>
            <!-- /.login-card-body -->
        </div>
    </div>
</div>