import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {functions,dataAnalysisFunction} from '@shared/constants/functions.constant';
@Component({
  selector: 'app-functions-dropdown',
  templateUrl: './functions-dropdown.component.html',
  styleUrls: ['./functions-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FunctionsDropdownComponent
    }
  ]
})
export class FunctionsDropdownComponent implements OnInit , ControlValueAccessor {

  touched = false;
  disabled = false;
  @Input() value = '';
  @Output() valueChange = new EventEmitter();
  @Input() functions = functions;
  @Input() isMultiple = false;
  @Input() isCloseOnSelect = true;
  @Input() placeholder = 'Funktion';
  @Input() isClearable = false;
  @Input() isDataAnalysis = false;
  @Output() functionChange = new EventEmitter();
  onChange = (value) => {};
  onTouched = () => {};

  constructor() { }

  // OnInit inteface function
  ngOnInit(): void {
    if (this.isDataAnalysis) {
      this.functions = dataAnalysisFunction;
    }
  }


 // ControlValueAccessor interface functions
  writeValue(value): void {
    this.value = value;
    console.log({value});
  }
  registerOnChange(onChangeRefFunc: any): void {
    this.onChange = onChangeRefFunc;
  }
  registerOnTouched(onTouchRefFunc: any): void {
    this.onTouched = onTouchRefFunc;
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }


  // class functions
  functionChanged(functionData): void {
    this.markAsTouched();
    this.functionChange.emit(functionData);
    this.valueChange.emit(this.value);
    this.onChange(this.value);
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

}
