import { LocalStorageService } from 'src/app/shared/storage/local-storage.service';
import { PreAuthPagesService } from '../../pre-auth-pages.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public APP_ROUTES = APP_ROUTES;
  public ChangePasswordForm: FormGroup;
  public isSubmitting = false;
  public isDisabled = false;
  public formSubmitSuccess = false;
  resetToken: any;

  constructor(private route: ActivatedRoute,
              private toastr: ToastrService,
              private router: Router,
              private fb: FormBuilder,
              private service: PreAuthPagesService,
              private ls: LocalStorageService) { }

  ngOnInit(): void {
    this.resetToken = this.route.snapshot.queryParamMap.get('token');
    this.checkResetToken(this.resetToken);
    this.initForm();
    this.ls.clear();
  }

  checkResetToken(resetToken: any): void {

    this.service.checkResetToken(resetToken).subscribe((response: any) => {
      if (response.status === 200) {
      }
    }, err => {
      this.toastr.error(err.error.message, '');
      this.router.navigate([APP_ROUTES.login]).then(() => {
        // for some async task
      });
    });
  }

  private initForm(): void {
    this.ChangePasswordForm = this.fb.group({
      newPassword: ['', [Validators.required,
        Validators.pattern(/^(?=.*\d.*\d)(?=.*[a-z].*[a-z].*[a-z].*[a-z])(?=.*[A-Z])(?=.*[$@$!%*?&#]).{8,8}$/)]],
      confirmNewPassword: ['', [Validators.required,  this.passwordMatcher.bind(this),
        Validators.pattern(/^(?=.*\d.*\d)(?=.*[a-z].*[a-z].*[a-z].*[a-z])(?=.*[A-Z])(?=.*[$@$!%*?&#]).{8,8}$/)]]
    });
  }

  private passwordMatcher(control: FormControl): { [s: string]: boolean } {

    if (this.ChangePasswordForm && (control.value !== this.ChangePasswordForm.controls.newPassword.value)) {
      return { passwordNotMatch: true };
    }
    return null;
  }


  change(e: number): void {
    if (this.ChangePasswordForm.value.newPassword !== this.ChangePasswordForm.value.confirmNewPassword) {
      this.toastr.error('Passwörter stimmen nicht überein.', '');
      return;
    }
    this.formSubmitSuccess = false;
    if (this.ChangePasswordForm.invalid) {
      this.isSubmitting = true;
      return;
    }
    this.isSubmitting = false;
    const payload = {
      token: this.resetToken,
      password: this.ChangePasswordForm.value.newPassword,
      confirmation_password : this.ChangePasswordForm.value.newPassword
    };
    this.service.changePassword(payload).subscribe((data: any) => {
      if (data.status === 200) {
        this.formSubmitSuccess = true;
        this.ChangePasswordForm.reset();

        setTimeout(() => {
          this.toastr.success('Ihr Passwort wurde geändert', '');
        }, 1000);
        this.router.navigate([APP_ROUTES.login]).then(() => {
        });
      }
    }, err => {
      this.toastr.error(err.error.message, '');
    });
  }

  get formControls(): any {
    return this.ChangePasswordForm.controls;
  }

}
