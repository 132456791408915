import { formatNumber } from '@angular/common';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Permission } from '@models/permission.model';
import * as roles from '../shared/constants/roles.constants';
import { APP_ROUTES } from '../shared/routes/appRoutes';
import { DASHBOARD_PERMISSION, MANAGE_USERS_PERMISSION, PERMISSIONS_URLS } from '@shared/constants/permissions.constants';

export const validateForm = (form: FormGroup) => {
    // tslint:disable-next-line:forin
    for (const controlKey in form.controls) {
        if (form.controls[controlKey] instanceof FormArray) {
            for (const formGroup of (form.controls[controlKey] as FormArray).controls) {
                // tslint:disable-next-line:forin
                for (const innerControlKey in (formGroup as FormGroup).controls) {
                    // tslint:disable-next-line:no-unused-expression
                    (formGroup as FormGroup).controls[innerControlKey].markAsTouched();
                }
            }
        }
        form.controls[controlKey].markAsTouched();
        form.controls[controlKey].updateValueAndValidity();
    }
};


// tslint:disable-next-line:no-unused-expression
export const converToFormdata = (data): FormData => {
    const formData = new FormData();
    // tslint:disable-next-line:forin
    for (const key in data) {
        if (key === 'images' && data[key] && data[key].length > 0) {
            data[key].forEach((image) => {
                formData.append(key + '[]', image);
            });
            continue;
        }
        formData.append(key, data[key]);
    }
    return formData;
};


export const getRouteAccordingToRole = (roleId, rolePermissions: Array<Permission>): string[] | false => {
    let route: string[] = [];
    switch (roleId) {
        case roles.APP_USER:
            return false;
        case roles.HSE_DEPARTMENT_MANAGER:
        case roles.ADMIN_GENERAL_MANAGER:
            route = ['/', APP_ROUTES.dashboard, APP_ROUTES.DashboardSiteOverView];
            if (!rolePermissions.find((permission) => permission.id === DASHBOARD_PERMISSION)) {
                route = PERMISSIONS_URLS[rolePermissions[0].id] ? PERMISSIONS_URLS[rolePermissions[0].id] : ['/', APP_ROUTES.ComingAccount];
            }
            return route;
        case roles.HSE_MANAGER:
            route = ['/', APP_ROUTES.ManageUsers];
            if (!rolePermissions.find((permission) => permission.id === MANAGE_USERS_PERMISSION)) {
                route = PERMISSIONS_URLS[rolePermissions[0].id] ? PERMISSIONS_URLS[rolePermissions[0].id] : ['/', APP_ROUTES.ComingAccount];
            }
            return route;
        default:
            if (!rolePermissions || rolePermissions.length === 0) {
                route = ['/', APP_ROUTES.NoPermission];
            } else {
                route = PERMISSIONS_URLS[rolePermissions[0].id];
            }
            return route;
    }


};

export const formatDate = (date): string => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
};

export const formatAMPM = (date): string => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    //var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    //var strTime = hours + ':' + minutes + ' ' + ampm;
    var strTime = hours + ':' + minutes + ' ' ;
    return strTime;
};