import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ChangePasswordComponent} from './forgot-password/change-password/change-password.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password/forgot-password.component';
import {LoginComponent} from './login/login.component';
import { PreAuthPagesRoutingModule } from './pre-auth-pages-routing.module';
import {SharedModule} from '../../shared/shared.module';


@NgModule({
  declarations: [
    ChangePasswordComponent,
    ForgotPasswordComponent,
    LoginComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PreAuthPagesRoutingModule
  ]
})
export class PreAuthPagesModule { }
