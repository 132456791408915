<div class="input-group input-group-style border-bottom bg-transparent">
    <div class="input-group bg-transparent mulitdropdown">
        <ng-select 
        [(ngModel)]="value"
        [items]="functions" 
        bindLabel="name" 
        bindValue="value" 
        [multiple]="isMultiple"
        [closeOnSelect]="isCloseOnSelect" 
        placeholder="placeholder"
        [clearable]="isClearable"
        (change)="functionChanged($event)" 
        ></ng-select>
    </div>
</div>