import { LoginComponent } from './pages/pre-auth-pages/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggedInUserGuard } from './auth/loggedInUser.guard';
import {GuestGuard} from './auth/guest.guard';

const routes: Routes = [
{
  path: '',
  canActivate: [LoggedInUserGuard],
  loadChildren: () => import('./layout/layout.module')
  .then((m) => m.LayoutModule)
}
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
