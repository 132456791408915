import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Permission } from '@models/permission.model';
import { TokenService } from '../services/token.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInUserGuard implements CanActivate {

  constructor(private router: Router, private location: Location, private tokenService: TokenService) {}
//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
//     if (localStorage.getItem('token') ) {
//         this.goBack();
//       return true
//     } else {
//     //   this.router.navigate(['/login']);
//       return false;
//     }
//   }

canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  if (localStorage.getItem('token')) {
      // const {role_id : roleid , roleArr } = JSON.parse(localStorage.getItem('currentUser'));
      // const path = getRouteAccordingToRole(roleid, roleArr);
      const {permission: routePermission} =  route.data;
      if (routePermission) {
        const permissions = this.tokenService.getPermissions();
        if (!permissions.find((permission: Permission) => Number(permission.id) === Number(routePermission))) {
          this.location.back();
          return false;
        }
        return true;
      }
      return true;
    } else {
      this.router.navigate(['/', 'login']);
      return false;
    }
  }

}
