import { PreAuthPagesService } from '../pre-auth-pages.service';
import { Component, OnInit } from '@angular/core';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {getRouteAccordingToRole} from '../../../helpers/functions';
import { TokenService } from 'src/app/services/token.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public APP_ROUTES = APP_ROUTES;
    public LoginForm: FormGroup;
    public isSubmitting = false;
    public isDisabled = false;
    public authorized = false;
    public passwordVisibility = false;

    constructor(
        private router: Router,
        private auth: PreAuthPagesService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private loaderService: NgxUiLoaderService,
        private tokenService: TokenService) { }

  ngOnInit(): void {
    this.initForm();
  }

  get formControls(): any {
        return this.LoginForm.controls;
    }

  private initForm(): void {
        this.LoginForm = new FormGroup({
            employee_code: new FormControl('', [Validators.required]),
            password : new FormControl('', [Validators.required])
        });
  }
  clearFocus(element: HTMLElement): void {
    element.blur();
  }

  public doLogin(e: Event): void {
        if (e.target) {
            this.clearFocus(e.target as HTMLElement);
        }
        if (this.LoginForm.invalid) {
            this.isSubmitting = true;
            return;
        }
        this.isSubmitting = false;
        this.loaderService.start();
        this.auth.login(this.LoginForm.value).subscribe((data: any) => {
            this.loaderService.stop();
            if (data.status === 200) {
                document.body.style.minHeight = 'auto';
                if (!data.isValidUser) {
                    this.toastr.error('Benutzer nicht erlaubt', '', {timeOut: 5000});
                    return;
                }
                const role = this.tokenService.getUserRole();
                const permissions  = this.tokenService.getPermissions();
                const path  = getRouteAccordingToRole(role.id, permissions);
                if ( !path) {
                    this.toastr.error('Benutzer nicht erlaubt', '', {timeOut: 5000 });
                }
                this.router.navigate(path as string[], {replaceUrl: true}).then(() => {
                });
            }
            else if (data.status === 403) {
                const title = 'Gesperrtes Konto';
                const html  = 'Ihr Konto wurde vom Administrator gesperrt';
                this.toastr.error('Ihr Konto wurde vom Administrator gesperrt', 'Gesperrtes Konto');
            }
            else{
                this.toastr.error(data.body.message, '');
            }
        }, err => {
            this.loaderService.stop();
            if (err.error.message) {
                this.toastr.error(err.error.message, '');
            }
            else {
                this.toastr.error('Something went wrong. Please try again later', '');
            }
        });
    }


}
