import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appWWWVToolTip]'
})
export class ToolTipDirective {
  @Input() content = '';
  @Input() tooltipHightOffset = 10;
  tooltip = null;
  @Input() isShow = true;

  constructor(private element: ElementRef) {
    element.nativeElement.addEventListener('mouseenter', () => {
      if (this.isShow) {
        const tooltip = document.createElement('div');
        tooltip.classList.add('tooltip-show');
        tooltip.textContent = this.content;
        element.nativeElement.style.position = 'relative';
        const elWidthCenter = element.nativeElement.offsetWidth / 2;
        element.nativeElement.append(tooltip);
        const left = elWidthCenter -  (tooltip.offsetWidth / 2);
        const top = - (tooltip.offsetHeight + this.tooltipHightOffset);
        tooltip.style.top = top + 'px';
        tooltip.style.left = left + 'px';
        this.tooltip = tooltip;
      }
    });

    element.nativeElement.addEventListener('mouseleave', () => {
      if (this.tooltip) {
        this.tooltip.remove();
      }
    });
  }

}
