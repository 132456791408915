<div class="login-page">

	<div class="login-box">
		<div class="login-logo mb-4">
			<a  href="javascript:;"><img src="/assets/img/logo.png" alt="logo" /></a>
		</div>
		<!-- /.login-logo -->
		<div class="card">
			<div class="card-body login-card-body">
				<h4 _ngcontent-lqd-c127="" class="text-center f-700"> Change password </h4>
				<hr>
				<p class="login-box-msg">You are only one step away from your new password,
					recover your password now.</p>

				<form novalidate [formGroup]="ChangePasswordForm">
					<div class="form-group">
						<label>New Password<span class="required-asterisk">*</span></label>
						<div class="input-group">
							
							<input type="password" class="form-control" formControlName="newPassword"
								autocomplete="off" placeholder="Enter password">
							<div class="input-group-append">
								<div class="input-group-text">
									<span class="fas fa-lock"></span>
								</div>
							</div>
						</div>
						<ng-container *ngIf="formControls['newPassword'].errors &&
							(ChangePasswordForm.get('newPassword').dirty ||
							ChangePasswordForm.get('newPassword').touched || isSubmitting)">
							<small class="text-danger error-msg"
								[hidden]="!formControls['newPassword'].errors.required">
								*Password is required.
							</small>
							<small class="text-danger error-msg"
								[hidden]="!formControls['newPassword'].errors.minlength">
								*Password must be minimum 8 character long.
							</small>
							<small class="text-danger error-msg" [hidden]="!formControls['newPassword'].hasError('pattern')">
								* Password must be 8 character long and must be the combination of <br/>
								* 5 Char with 1 Upper Case <br/>
								* 2 Numerical <br/>
								* 1 Special Char allowed characters are $@!%*?&amp;#<br/>
							</small>
							<!-- <small class="text-danger error-msg" [hidden]="ChangePasswordForm.errors?.passwordNotMatch">
								Passwords do not match
							</small> -->
						</ng-container>
					</div>

					<div class="form-group">
						<label >Retype Password<span class="required-asterisk">*</span></label>
						<div class="input-group">
						
							<input type="password" class="form-control"
								formControlName="confirmNewPassword" autocomplete="off" placeholder="Confirm password">
							<div class="input-group-append">
								<div class="input-group-text">
									<span class="fas fa-lock"></span>
								</div>
							</div> 
						</div>
						<ng-container
							*ngIf="formControls['confirmNewPassword'].errors &&
							(ChangePasswordForm.get('confirmNewPassword').dirty ||
							ChangePasswordForm.get('confirmNewPassword').touched || isSubmitting)">
							<small class="text-danger error-msg"
								[hidden]="!formControls['confirmNewPassword'].errors.required">
								*Password is required.
							</small>
							<small class="text-danger error-msg"
								[hidden]="!formControls['confirmNewPassword'].errors.minlength">
								*Password must be minimum 8 character long.
							</small>
							<small class="text-danger error-msg"
								[hidden]="ChangePasswordForm.errors?.passwordNotMatch">
								Passwords do not match
							</small>
							<small class="text-danger error-msg" [hidden]="!formControls['confirmNewPassword'].hasError('pattern')">
								<br/>* Password must be 8 character long and must be the combination of <br/>
								* 5 Char with 1 Upper Case <br/>
								* 2 Numerical <br/>
								* 1 Special Char allowed characters are $@!%*?&amp;#<br/>
							</small>
						</ng-container>
					</div>

					<div class="row">
						<div class="col-12">
							<button type="submit" class="btn btn-primary btn-lg btn-block"
								(click)="change($event)">Change password</button>
						</div>
						<!-- /.col -->
					</div>
				</form>

				<!-- <p class="mt-3 mb-1">
			<a class="btn btn-block btn-outline-primary" href="javascript:;" [routerLink]="['../' + APP_ROUTES.login]">Login</a>
		  </p> -->
			</div>
			<!-- /.login-card-body -->
		</div>
	</div>

</div>
