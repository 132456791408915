import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-validaton-errors',
  templateUrl: './validaton-errors.component.html',
  styleUrls: ['./validaton-errors.component.scss']
})
export class ValidatonErrorsComponent implements OnInit {

  @Input() control = null;
  @Input() controlName = 'field';
  @Input() language='English'
  constructor() { }

  ngOnInit(): void {
  }

  getFormattedDate(date: Date): string {
    return moment(date).format('M/D/YYYY, hh:mm a');
  }
}
